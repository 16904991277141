<template>
  <v-container fluid class="10">
    <v-row class="ml-7" style="with: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab> All Products </v-tab>
            <v-tab> All Flash </v-tab>

            <v-tab-item>
              <div>
                <CreateFlash></CreateFlash>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div>
                <AllFlash></AllFlash>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  components: {
    CreateFlash: () => import("@/components/FlashSale/CreateFlashSale.vue"),
    AllFlash: () => import("@/components/FlashSale/AllFlash.vue"),
  },
};
</script>

<style>
</style>